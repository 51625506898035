import React, {useEffect, useRef} from 'react'
import { Modal } from "react-responsive-modal"
import "react-responsive-modal/styles.css"
import scrollTo from 'gatsby-plugin-smoothscroll'

import { Splide, SplideSlide } from '@splidejs/react-splide'
import '../../node_modules/@splidejs/splide/dist/css/splide.min.css'

import { Tween, Timeline, Reveal } from 'react-gsap';
import backToTop from '../images/buttons/back-to-top-white.svg'
import playButton from '../images/playBtnDefault.png'
import playButtonWheelMan from '../images/playBtnWheelMan.png'
import playBtnSpotter from '../images/playBtnSpotter.png'
import playBtnTreadLightly from '../images/playBtnTreadLightly.png'
import playBtnHighSpeed from '../images/playBtnHighSpeed.png'
import playLocked from '../images/bg-locked.svg'
import arrowsRedBtn from '../images/buttons/arrows.svg'

import WheelMan from '../images/logos/badges/wheelman.svg'
import HiSpeedRez from '../images/logos/badges/hispeedrez.svg'
import TreadLightly from '../images/logos/badges/treadlightly.svg'
import Spotter from '../images/logos/badges/spotter.svg'
import Elevated from '../images/logos/badges/elevated.svg'
import Convoy from '../images/logos/badges/convoy.svg'
import SquadUp from '../images/logos/badges/squadup.svg'
import LongView from '../images/logos/badges/longview.svg'
import LockBadge from '../images/logos/badges/lock-badge.svg'

export default function Contracts() {
  const ref = useRef()
  const heroRef = React.useRef(null); 

  useEffect(() => {
    console.log(heroRef.current.offsetTop)
  }, [])

  const [openFirst, setOpenFirst] = React.useState(false);

  useEffect( () => {
      if ( ref.current ) {
          console.log( ref.current.splide.index + '/' + ref.current.splide.length );
      }
  } );
  const currentSlide = ref.current ? ref.current.splide.index + 1 : '1'
  const totalSlides = ref.current ? ref.current.splide.length : '5'    

  const handleThumbs = (id) => {
    if ( ref.current ) {
      ref.current.splide.go(id);
    }
  };

  const contractItems = [
    {
      challengeNumber: '1',
      challengeTitle: "WHEEL MAN",
      challengeShortDescription: "Maneuver using CrabWalk¹.",
      challengeDescription: "Each challenger must maneuver the HUMMER EV through an obstacle course using CrabWalk¹.",
      challengeURL: "https://www.youtube.com/embed/pTiI02yycbE?rel-0",
      challengeBadge: WheelMan,
      challengePlayStatus: playLocked,
      challengeStatus: "Locked"
    },
    {
      challengeNumber: '2',
      challengeTitle: "TREAD LIGHTLY",
      challengeShortDescription: "Harness the UltraVision² cameras.",
      challengeDescription: "Pairs of teammates will harness the HUMMER EV's UltraVision² cameras to get past the proximity hot spots.",
      challengeURL: "https://www.youtube.com/embed/pTiI02yycbE?rel-0",
      challengeBadge: TreadLightly,
      challengePlayStatus: playLocked,
      challengeStatus: "Locked"
    },
    {
      challengeNumber: '3',
      challengeTitle: "HI-SPEED RES",
      challengeShortDescription: "Unleash Watts To Freedom³.",
      challengeDescription: "Use the HUMMER EV's in Watts To Freedom³ to get to, find and rescue their teammate as fast as possible.",
      challengeURL: "https://www.youtube.com/embed/pTiI02yycbE?rel-0",
      challengeBadge: HiSpeedRez,
      challengePlayStatus: playLocked,
      challengeStatus: "Locked"
    },
    {
      challengeNumber: '4',
      challengeTitle: "SPOTTER",
      challengeShortDescription: "Find loadouts through the Infinity Roof.",
      challengeDescription: "See who is the best spotter by finding hidden loadout crates using the Hummer EV's Infinity Roof.",
      challengeURL: "https://www.youtube.com/embed/pTiI02yycbE?rel-0",
      challengeBadge: Spotter,
      challengePlayStatus: playLocked,
      challengeStatus: "Locked"
    },
    {
      challengeNumber: '5',
      challengeTitle: "ELEVATED",
      challengeShortDescription: "Drive a Hummer EV to the map's highest point.",
      challengeDescription: "Drive a HUMMER EV to the highest natural point on the map, near Zaya Observatory.",
      challengeURL: "https://www.youtube.com/embed/pTiI02yycbE?rel-0",
      challengeBadge: LockBadge,
      challengePlayStatus: playLocked,
      challengeStatus: "Locked"
    },
    {
      challengeNumber: '6',
      challengeTitle: "CONVOY!",
      challengeShortDescription: "Get four Hummer EVs Driving Side by Side.",
      challengeDescription: "Find a HUMMER EV for each teammate, then drive all four side by side.",
      challengeURL: "https://www.youtube.com/embed/pTiI02yycbE?rel-0",
      challengeBadge: LockBadge,
      challengePlayStatus: playLocked,
      challengeStatus: "Locked"
    },
    {
      challengeNumber: '7',
      challengeTitle: "SQUAD UP",
      challengeShortDescription: "Get all teammates into one Hummer EV.",
      challengeDescription: "Get all Four teammates into one HUMMER EV and complete a long run together.",
      challengeURL: "https://www.youtube.com/embed/pTiI02yycbE?rel-0",
      challengeBadge: LockBadge,
      challengePlayStatus: playLocked,
      challengeStatus: "Locked"
    },
    {
      challengeNumber: '8',
      challengeTitle: "LONGVIEW",
      challengeShortDescription: "Spot your teammate in a Hummer EV.",
      challengeDescription: "From far away, spot your teammate who is standing in a HUMMER EV.",
      challengeURL: "https://www.youtube.com/embed/pTiI02yycbE?rel-0",
      challengeBadge: LockBadge,
      challengePlayStatus: playLocked,
      challengeStatus: "Locked"
    }
  ]


  return (
    <>
    <Reveal repeat trigger={<div />}>
    <Tween from={{ opacity: 0 }} duration={3}>

    <div ref={heroRef} id="theChallenges" className="items-center justify-center " />

    <div className="h-screen relative flex items-center bg-challenge-background bg-cover">
        <div className="grid grid-cols-1 w-full gap-0 h-full absolute z-0 overflow-scroll bg-no-repeat p-5 py-20 md:grid-cols-3 md:gap-5 xl:px-20 lg:pt-24">
          <div className="m-auto">
            <div className="w-full md:w-3/4">
              <h2 className="text-3xl lg:text-5xl lg:mb-5 font-StratumBold uppercase">Team Challenges</h2>
              <p className="font-Inter mb-5 lg:mb-0 text-lg">Squad up and drop in. It's time to test the Team's skills with the GMC HUMMER EV.</p>
            </div>
          </div>
          <div className="col-span-2 m-auto w-full">
            <div className="grid grid-cols-1 grid-rows-0 gap-3 mx-auto md:grid-cols-2 md:grid-rows-4 md:grid-flow-col max-w-sm md:max-w-2xl">

                {/* {contractItems.map((item, index) => (
                <button 
                  className="text-left" 
                  onClick={event => {
                    setOpenFirst(true)
                    handleThumbs(index)
                  }}
                  key={index}>
                    <div className="relative bg-play-card bg-cover w-full grid grid-cols-3 gap-0 border border-military-200 p-2 rounded rounded-md lg:rounded-md border-1">
                      <div className="">
                        <img src={item.challengePlayStatus} className="w-full sm:w-24 lg:h-24" />
                      </div>
                      <div className="col-span-2 px-2 md:p-2">
                        <span className="font-StratumBold text-sm text-red-600">{item.challengeNumber} of 8</span>
                        <h3 className="font-StratumBold uppercase text-sm lg:text-lg">{item.name}</h3>
                        <p className="text-sm md:text-sm font-Inter">{item.challengeShortDescription}</p>
                        <div className="w-5 absolute right-0 bottom-3">
                            <img src={arrowsRedBtn} className="w-3 h-3" />
                        </div>
                      </div>
                    </div>
                </button>
                ))} */}

                {contractItems.map((item, index) => (
                <div 
                  className="text-left" 
                  key={index}>
                    <div className="relative bg-play-card bg-cover w-full grid grid-cols-3 gap-0 border border-military-200 p-2 rounded rounded-md lg:rounded-md border-1">
                      <div className="">
                        <img src={item.challengePlayStatus} className="w-full sm:w-24 lg:h-24" />
                      </div>
                      <div className="col-span-2 px-2 md:p-2">
                        <span className="font-StratumBold text-sm text-red-600">{item.challengeNumber} of 8</span>
                        <h3 className="font-StratumBold uppercase text-sm lg:text-lg">{item.name}</h3>
                        <p className="text-sm md:text-sm font-Inter">{item.challengeShortDescription}</p>
                        <div className="w-5 absolute right-0 bottom-3">
                            <img src={arrowsRedBtn} className="w-3 h-3" />
                        </div>
                      </div>
                    </div>
                </div>
                ))}

            </div>
          </div>
        </div>
            
        <div className="h-20 lg:h-36 bg-gradient-to-b from-black absolute w-full top-0 z-0" />

    </div>

    </Tween>
    </Reveal>

    <Modal 
    open={openFirst} 
    onClose={() => {
        setOpenFirst(false)
        scrollTo(`#theChallenges`)
    }}
    center 
    classNames={{
        overlay: '',
        closeButton: '!hidden',
        modal: '!max-w-none !m-0 !p-0 !bg-transparent !fixed top-0 left-0 hidden !w-full h-full outline-none overflow-x-hidden overflow-y-auto',
    }}
    >
    <div className="relative w-full">
        <div className="h-full bg-gradient-to-b from-black absolute w-full top-0 ">
            <div className="flex h-screen relative flex bg-black flex-col w-full pointer-events-auto text-black">
                <button 
                  type="button" 
                  className="absolute top-0 p-5 lg:top-5 left-0 lg:left-5 bg-black lg:bg-transparent z-20 lg:p-0 md:w-80" 
                  onClick={() => {
                      setOpenFirst(false)
                      scrollTo(`#theChallenges`)
                  }}
                >
                  <img src={backToTop} className="w-80" /></button>
                <Splide 
                    ref={ref}
                    options={ {
                        perPage: 1,
                        gap   : '0.5rem',
                        type  : 'fade',
                        rewind : false,
                        speed : 1000,
                        pagination : false,
                        classes: {
                            pagination: 'splide__pagination px-10 bottom-0 absolute left-0 right-0 grid grid-cols-12 gap-4 text-center',
                            page      : 'splide__pagination__page !bg-red-600 [&:not(.is-active)]:!bg-gray-400 !my-5 !scale-150 [&:not(.is-active)]:!scale-105',
                            arrows    : 'splide__arrows !absolute !bottom-36 !md:bottom-44 md:!w-40 !w-52 md:!left-36 !left-1/2 !-translate-x-1/2 !-translate-y-1/2 z-40',
                            arrow     : 'splide__arrow !rounded-none !bg-transparent md:!w-14 md:!h-14 !w-20 !h-20 !rounded-none lg:!w-14 !w-20',
                            prev      : 'splide__arrow--prev !bg-prev-arrow !bg-no-repeat !bg-contain md:!w-14',
                            next      : 'splide__arrow--next !bg-next-arrow !bg-no-repeat !bg-contain md:!w-14',
                        },    
                    } }
                    aria-label="latest-projects"
                >
                  {contractItems.map((item, index) => (
                    <SplideSlide 
                      className="h-screen relative flex items-center overflow-scroll"
                      key={index}>
                      <div className="flex flex-col-reverse gap-10 pb-28 md:pb-0 md:grid grid-col-1 md:grid-cols-3 md:gap-0 w-full z-40 lg:pt-32">
                        <div className="">
                        <div className="text-left inline-block lg:flex lg:h-screen z-20">
                          <div className="md:m-auto md:pb-20 text-white lg:text-left pl-5 grid grid-cols-4 gap-0">
                            <div className="">
                              <img src={item.challengeBadge} className="w-28" />
                            </div>
                            <div className="col-span-3 pl-5">
                              <span className="font-StratumBold text-sm text-red-600">{item.challengeNumber} of 8</span>
                              <h3 className="text-lg lg:text-3xl font-StratumBold uppercase">{item.challengeTitle}</h3>
                              <p className="text-xs lg:text-sm font-Inter">{item.challengeDescription}</p>
                            </div>
                          </div>
                        </div>
                        </div>
                        <div className="col-span-2 md:m-auto md:pb-20 md:px-10 md:inline-block w-full">
                            <div className="block md:my-30 md:mx-auto">
                              {item.challengeStatus == "Locked" ? 
                                <div className="aspect-w-16 aspect-h-9 bg-play-locked bg-contain bg-no-repeat bg-center"></div>
                                : 
                                <div className="aspect-w-16 aspect-h-9"> 
                                    <iframe width="560" height="315" src={item.challengeURL} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                              }
                            </div>
                        </div>
                      </div>
                    </SplideSlide>
                  ))}
                </Splide>
            </div>
        </div>
    </div>
    </Modal>
    </>
  )
}
