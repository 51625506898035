import React, {useEffect} from 'react'
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';
import { Modal } from "react-responsive-modal"
import "react-responsive-modal/styles.css"
import { Tween, Reveal } from 'react-gsap';
import scrollTo from 'gatsby-plugin-smoothscroll'

import hummerEVpromoMobile from '../videos/loop3-1080p-mobile.mp4'
import hummerEVpromoDesktop from '../videos/loop3-1080p-desktop.mp4'
import hummerEVpromoDesktopCover from '../images/hummer-driving-desktop.jpg'
import hummerEVpromoMobileCover from '../images/hummer-driving-mobile.jpg'
import WatchTrailerButton from '../images/buttons/watch-the-trailer.svg'

import backToTop from '../images/buttons/back-to-top-white.svg'

export default function Hero() {
  const heroRef = React.useRef(null); 

  useEffect(() => {
    console.log(heroRef.current.offsetTop)
  }, [])

  const [openFirst, setOpenFirst] = React.useState(false);

  const closeIcon = (
    <svg width="45" height="46" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_45_230)">
    <rect width="23" height="1" transform="translate(21 5.22182) rotate(135)" fill="white"/>
    </g>
    <g filter="url(#filter1_d_45_230)">
    <rect width="23" height="1" transform="translate(20.2928 21.4853) rotate(-135)" fill="white"/>
    </g>
    <defs>
    <filter id="filter0_d_45_230" x="0.0294189" y="0.514709" width="24.9706" height="24.9706" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset/>
    <feGaussianBlur stdDeviation="2"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_45_230"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_45_230" result="shape"/>
    </filter>
    <filter id="filter1_d_45_230" x="0.0294189" y="0.514709" width="24.9705" height="24.9706" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset/>
    <feGaussianBlur stdDeviation="2"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_45_230"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_45_230" result="shape"/>
    </filter>
    </defs>
    </svg>
  );

  return (
    <>
    <Reveal repeat trigger={<div />}>
    <Tween from={{ opacity: 1 }} duration={3}>
    <ParallaxProvider>
    <div ref={heroRef} id="hero" className="snap-start w-screen flex items-center justify-center" />
        

    <div className="h-screen relative flex items-center overflow-hidden ">
        <video 
            width="100%" 
            autoPlay 
            loop 
            muted 
            playsInline 
            poster={hummerEVpromoMobileCover} 
            id="heroVideo" 
            className="block lg:hidden absolute w-auto min-w-full min-h-full object-cover"
        >
        <source
            src={hummerEVpromoMobile}
            type="video/mp4"
        />
        </video>
        <video 
            width="100%" 
            autoPlay 
            loop 
            muted 
            playsInline 
            poster={hummerEVpromoDesktopCover} 
            id="heroVideo" 
            className="hidden lg:block absolute w-auto min-w-full min-h-full object-cover"
        >
        <source
            src={hummerEVpromoDesktop}
            type="video/mp4"
        />
        </video>
        <div className="relative h-screen bg-center md:bg-cover py-20">

            <div className="grid grid-cols-1 lg:grid-cols-12 gap-0 lg:gap-4 h-full p-5 pb-0 lg:px-5 lg:pt-20">
                <div className="col-span-4">
                    <div className="">
                        <div className="text-center">
                            <h2 className="
                                text-6xl 
                                lg:text-7xl 
                                xl:text-8xl 
                                opacity-30 
                                text-left 
                                font-StratumBold 
                                uppercase"
                            >CHALLENGE ACCEPTED</h2>
                            <p className="font-Inter text-lg mt-3 leading-6 text-left">Watch Team AchiEV master the GMC HUMMER EV. It's the baddest new vehicle on the map in Call of Duty®: Warzone 2.0™ — and in real life.</p>
                        </div>
                    </div>
                </div>
                <div className="hidden md:block md:col-span-4" />
                <div className="md:col-span-4 relative w-full">
                    <button 
                        type="button" 
                        className="absolute bottom-5 md:bottom-5 right-0 md:top-auto"
                        onClick={() => setOpenFirst(true)}
                    >
                        <img src={WatchTrailerButton} className="w-80 inline-block float-right" />
                    </button>
                </div>
            </div>


            {/* <div className="grid grid-cols-1 lg:grid-cols-2 gap-0 lg:gap-4 h-full p-5 pb-0 lg:px-5 lg:pt-20 z-0 ">
                <div className="">
                    <Parallax speed={-100}>
                        <div className="text-center">
                            <h2 className="text-[62px] leading-[52px] sm:text-[260px] sm:leading-[180px] md:text-[120px] opacity-30 md:leading-[100px] text-left font-StratumBold uppercase">CHALLENGE ACCEPTED</h2>
                            <p className="font-Inter text-lg mt-3 leading-6 text-left lg:w-[500px]">Watch Team AchiEV master the GMC HUMMER EV. It's the baddest new vehicle on the map in Call of Duty®: Warzone 2.0™ — and in real life.</p>
                        </div>
                    </Parallax>
                </div>
                <div className=''>
                    <Parallax speed={-100}>
                    <div className="relative w-full bottom-[-150px] md:bottom-[-200px] lg:bottom-[-300px]">
                        <div className="absolute top-0 bottom-0 right-0 md:top-auto">
                            <button type="button" className="w-full" onClick={() => setOpenFirst(true)}>
                                <img src={WatchTrailerButton} className="w-80" />
                            </button>
                        </div>
                    </div>
                    </Parallax>
                </div>
                
            </div> */}

        </div>

        <div className="h-20 lg:h-30 bg-gradient-to-t from-black absolute w-full bottom-0" />
        {/* <div className="h-full w-full animate-fade z-20 duration-500 ease-in transform transition duration-500 active:scale-0 " /> */}
        {/* <div className="h-full w-full animate-fade z-20 duration-500 ease-in transform transition duration-500 default:scale-0 " /> */}
    </div>

    </ParallaxProvider>
    </Tween>
    </Reveal>
    <Modal 
        open={openFirst} 
        onClose={() => {
            setOpenFirst(false)
            scrollTo(`#hero`)
        }}
        center 
        closeIcon={closeIcon}
        classNames={{
            overlay: '!bg-black',
            closeButton: '!top-5 !right-5 !p-0 !bg-black',
            modal: '!max-w-none !m-0 !p-5 lg:!p-10 lg:!p-0 !bg-transparent !fixed top-0 left-0 hidden !w-full h-full outline-none overflow-x-hidden overflow-y-auto',
        }}
    >
        <div className="relative w-full">
            <div className="h-full bg-gradient-to-b from-black absolute w-full top-0" />
            <div className="flex h-screen relative flex flex-col w-full pointer-events-auto text-black">
            <div className="relative h-full bg-center bg-cover md:p-5">
            <button 
                type="button" 
                className="w-80" 
                onClick={() => {
                    setOpenFirst(false)
                    scrollTo(`#hero`)
                }}
            >
                <img src={backToTop} className="w-full" />
            </button>
            <div className=" w-full h-full p-0">
                <div className="aspect-w-16 aspect-h-9"> 
                <iframe width="560" height="315" src="https://www.youtube.com/embed/pTiI02yycbE?rel-0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
            </div>
            </div>
            </div>
        </div>
    </Modal>
    </>
  )
}
